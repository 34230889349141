import * as React from "react";
import Layout from "../components/layout";
import { Col, Row } from "react-bootstrap";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

const IndexPage = () => {
  return (
    <Layout>
      <Row className="mb-3">
        <Col>
          <StaticImage
            src="../images/img_non_profit.jpg"
            alt=""
            placeholder="blurred"
          />
        </Col>
      </Row>

      <Row className="about">
        <Col>
          <h1>Non-Profit-Services</h1>
          <p>
            Funded by the donations of our members and generous donors from
            enterprises in the health care sector, we are able to offer many of
            our world-class treatments and services for free to eligible
            persons.
          </p>

          <h2 className="mt-5 mb-3">Am I eligible?</h2>
          <p>
            To find out whether you are eligible for a free treatment at one of
            our facilities, <Link to="/contact">please contact us</Link>. We
            will then discuss possible further steps for an application with
            you.
          </p>
          <p className="fw-bold">
            The application and initial online examination process is completely
            free for you. Therefore, the application bears no cost risk for you.
          </p>

          <h2 className="mt-5 mb-3">What happens then?</h2>
          <p>
            Each case is examined in detail before we make a decision about the
            eligibility. We take all specific personal aspects into account,
            therefore we can't make general statements about who might be
            eligible or not. Nevertheless, these are some basic factors that are
            usually taken into account:
          </p>
          <ul>
            <li>
              Severity - How strong is the applying person affected by the
              condition?
            </li>
            <li>
              Physical / Medical Effects - Does the person suffer from immediate
              physical or medical effects caused by the condition or is the
              persons daily life affected?
            </li>
            <li>
              Psychological Effects - Does the person suffer psychological
              effects like reduced self-esteem?
            </li>
            <li>
              Life Circumstances - Is the person in a economically difficult
              situation and cannot afford a surgery or does the person life in a
              country with insufficient medical treatment options?
            </li>
          </ul>

          <p>
            Our Foundation Committee Board decides about which of the presented
            cases are eligible for a free treatment. We cover the complete costs
            including all expenses for travel and accommodation at the location
            of one of our partner health centers.
          </p>

          <p>
            Even if you are not eligible, we might still be able to offer you a
            treatment at fair prices with financing support.{" "}
            <Link to="/contact">Please contact us for further details</Link>.
          </p>
        </Col>
      </Row>
    </Layout>
  );
};

export default IndexPage;
